
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { OpenURL } from '@/components/article-page/types'
import { encodeUrl } from '@/utils/utils'

// import { simplifyUrl } from '@/utils'
// TODO: I had to move function here becuase the import was making Jest fail
// and I haven't figured out why yet. We need to start migrating .js files to .ts files
// and @/utils is pointing to utils.js. There is a @/utils/utils.ts file we could
// start migrating to.
const simplifyUrl = (url) => {
  const simpleUrl = url.replace('http://', '').replace('https://', '').replace('www.', '')
  return simpleUrl.substr(0, simpleUrl.indexOf('/'))
}

@Component
export default class GenericFormat extends Vue {
  @Prop() sourceUrl! : string
  @Prop() openUrl!: OpenURL
  @PropSync('showModal', { type: Boolean }) syncedShowModal!: boolean

  get simpleSourceUrl() {
    return this.sourceUrl && this.sourceUrl.length > 0 ? simplifyUrl(this.sourceUrl) : ''
  }

  get encodedSourceUrl() {
    if (!this.sourceUrl) {
      return ''
    }

    // return this.sourceUrl.replace(/&/g, '%26').replace(/;/, '%3B')
    return encodeUrl(this.sourceUrl)
  }
}
